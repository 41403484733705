import { useState } from 'react';
import './App.css';
import { Button, Input, Row, Col, Space, Divider, Skeleton, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import JsonView from '@uiw/react-json-view';
import { darkTheme } from '@uiw/react-json-view/dark';

const { TextArea } = Input;

const BASE_URL = "https://api.cloudflare.com/client/v4";

function App() {
  const [fetching, setFetching] = useState(false);
  const [applying, setApplying] = useState(false);
  const [authToken, setAuthToken] = useState(undefined);
  const [zoneID, setZoneID] = useState("");
  const [accID, setAccID] = useState("");
  const [input, setInput] = useState("");
  const [output, setOutput] = useState([]);

  const applyConfigurations = async () => {
    if (output.length < 1) {
      alert("No configurations to apply")
      return;
    }

    if (!authToken) {
      alert("No Cloudflare auth token provided")
      return;
    }

    for (let i = 0; i < output.length; i++) {
      const resource = output[i];
      console.log(resource);

      fetch(BASE_URL + resource.endpoint, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authToken,
        },
        body: JSON.stringify(resource.config)
      }).then(async (response)=> {
        const j = await response.json();
        console.log(j);
      })
    }
  }

  const onCreate = () => {
    if (fetching) return;

    if (!zoneID) {
      alert("No zone ID provided");
      return;
    }

    if (!accID) {
      alert("No account ID provided");
      return;
    }

    setOutput("");
    setFetching(true);
    fetch("https://lb-config-generator-backend.cole.ctm-demo.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: input,
      })
    }).then(async (response)=> {
      const j = await response.json();
      console.log(j);
      for (let i = 0; i < j.length; i++) {
        const res = j[i]
        j[i] = {
          type: res.type,
          endpoint: res.endpoint.replace("{zone_id}", zoneID),
          config: res.config,
        }
      }
      setOutput(j);
      setFetching(false);
    }).catch((e) => {
      setFetching(false);
      setOutput([]);
      alert("Failed to generate")
    })
  }

  const getCurl = (resource) => {
    return `curl -X POST -H "Authorization: Bearer ${authToken || "PLACEHOLDER"}" -H "Content-Type: application/json" --data '${JSON.stringify(resource.config)}' ${BASE_URL}${resource.endpoint.replace("{zone_id}", zoneID).replace("{account_id}", accID)}`
  }

  return (
    <div>
      <Row>
        <div>
          <h1 style={{color: "orange", padding: 0, margin: 0, marginBottom: "5px"}}>Cloudflare Load Balancing</h1>
          <span style={{color: "lightgray"}}>Configuration Tool</span>
        </div>
      </Row>
      <Row style={{marginTop: "25px", width:'100%'}}>
        <Col xs={24}>
          <Space>
            <Input disabled={output.length > 0 || fetching} placeholder='Enter zone tag'
            onChange={(e)=>setZoneID(e.target.value)}
            ></Input>
            <Input disabled={output.length > 0 || fetching} placeholder='Enter account tag'
            onChange={(e)=>setAccID(e.target.value)}
            ></Input>
            </Space>
        </Col>
      </Row>
      <Row style={{marginTop: "25px"}}>
        <TextArea disabled={output.length > 0 || fetching}  placeholder='Describe your load balancer' rows={8}
        onChange={(e)=>setInput(e.target.value)}></TextArea>
      </Row>
      {output.length < 1 && <Row style={{marginTop: "25px"}}>
        <Button type="primary" onClick={onCreate} icon={fetching ? <LoadingOutlined/> : undefined}>{fetching ? "Please wait" : "Create"}</Button>
      </Row>}
      <Row style={{ width:'100%'}}>
        {output.length > 0 &&<Divider style={{backgroundColor: "orange"}}></Divider>}
          {output.length > 0 && <div style={{width:"100%", color: "white"}}>
              <div style={{width:'100%', marginTop2: '25px'}}>
                <Space size={20}>
                  <Input style={{width: '325px'}} disabled={applying} placeholder='Enter your API token'
                    onChange={(e)=>setAuthToken(e.target.value)}
                    ></Input>
                    <Button type="primary" onClick={applyConfigurations} icon={applying ? <LoadingOutlined/> : undefined}>{applying ? "Applying" : "Apply Configurations"}</Button>
                    <Button type="primary" onClick={() => {
                      setOutput([]);
                      setApplying(false);
                      setFetching(false);
                      setAuthToken(undefined);
                    }}>Clear Output</Button>
                </Space>
              </div>

              {output.map((resource)=> {
                return (
                  <div style={{border: '1px solid gray', padding: '15px', marginTop: '25px'}}>
                    <h2 style={{color:"orange", marginTop: 0}}>{resource.type}</h2>
                    <JsonView value={resource.config} style={darkTheme} indentWidth={15}></JsonView>
                    <Divider style={{backgroundColor: 'darkgray'}}></Divider>
                    <Typography.Text style={{color:'white'}} copyable>{getCurl(resource)}</Typography.Text>
                  </div>
                )
              })}
            </div>
          }
          {output.length < 1 && <Skeleton active style={{width:'100%'}}></Skeleton>}
      </Row>
    </div>
  );
}

export default App;

/*
4bc8fc8bbfcd25b69c9274bc5645a40b zone

c8d3204f11d52b316d24328b9b09b9c6 acc
*/

/*


Two pools. One in WNAM and one in ENAM. I want to load balance traffic between each pool randomly, with 60% going to WNAM.

WNAM pool with origin 1.2.3.4 and all data centers monitoring.
ENAM pool with origin 5.6.7.8 and all regions monitoring.

All origins monitored every 33 seconds via HTTP endpoint /health on origin port 8080.

Session affinity by cookie with TTL of 355 seconds.

Also I want a rule to return a fixed response if the path contains "test".

Hostname of LB: demo-lb.cole.ctm-demo.com


*/
